// import { render } from 'react-dom'
import ReactDOM from 'react-dom/client'; // 'react-dom' 대신 'react-dom/client' 사용

import App from './App'
import { ThemeProvider } from './contexts/theme'
import './index.css'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
)
