
const header = { 
  homepage: '#', 
  title: 'Jay Two',
}

const about = {
  name: 'Jay Two' ,
  role: 'Software' ,
}

const projects = [ 
  
  {
    name: 'Open Source', 
    thumbnail: 'images/open-source.png',        
    livePreview: 'https://j2doll.github.io/open-source/',
  }, 
  
  {
    name: 'App', 
    thumbnail: 'images/sw.png',    
    livePreview: 'https://j2doll.github.io/app/',
  }, 

  {
    name: 'Article', 
    thumbnail: 'images/article.png',    
    livePreview: 'https://j2doll.github.io/article/',
  }, 

]

const skills = [
]

const contact = {
  email: 'jaytwo@jaytwo.kr',
}

export { header, about, projects, skills, contact }


